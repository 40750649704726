<template>
  <v-app>
    <v-content class="white pb-10">
      <section id="landing_header">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="py-0 d-flex"
            :class="{
              'justify-center': $vuetify.breakpoint.smAndDown,
              'offset-1': $vuetify.breakpoint.mdAndUp
            }"
          >
            <v-card
              :height="logoSize"
              :width="logoSize"
              elevation="6"
              class="image__logo my-0"
              :style="logoStyle"
            >
              <v-row
                no-gutters
                justify="center"
                align="center"
                :style="{ height: logoSize }"
              >
                <v-col cols="10">
                  <v-img
                    :width="logoSize"
                    :height="logoSize"
                    :aspect-ratio="1"
                    contain
                    :src="logo"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-img
              :aspect-ratio="1"
              :max-height="minHeight"
              :src="image_header"
              class="white--text"
            >
              <v-row justify="end" v-if="$vuetify.breakpoint.smAndDown">
                <v-col cols="3" class="d-flex justify-end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        :small="$vuetify.breakpoint.smAndDown"
                        color="white"
                        class="mr-4 mt-5"
                        v-on="on"
                        style="padding-left: 10px !important; padding-right: 10px !important"
                      >
                        <div style="font-size:20px">
                          <flag
                            :iso="
                              language == 'en'
                                ? 'us'
                                : language == 'zh'
                                ? 'cn'
                                : language
                            "
                          />
                        </div>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="changeLang('es')">
                        <v-list-item-title>
                          <div style="font-size:20px">
                            <flag iso="es" />
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeLang('en')">
                        <v-list-item-title>
                          <div style="font-size:20px">
                            <flag iso="us" />
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeLang('fr')">
                        <v-list-item-title>
                          <div style="font-size:20px">
                            <flag iso="fr" />
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeLang('zh')">
                        <v-list-item-title>
                          <div style="font-size:20px">
                            <flag iso="cn" />
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-img>
            <base-divider color="landing_primary" max-width="100%" space="8" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            lg="4"
            class="mt-5"
            :class="{
              'text-center': $vuetify.breakpoint.smAndDown,
              'offset-1': $vuetify.breakpoint.mdAndUp
            }"
          >
            <span
              class="landing_primary--text font-weight-bold"
              :style="fontSize"
              v-text="name_restaurant"
            ></span>
          </v-col>
          <v-col
            cols="8"
            md="7"
            lg="7"
            class="mt-5"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-btn
              color="landing_primary"
              class="mr-3"
              outlined
              depressed
              rounded
              :to="{ name: 'menus-landing' }"
              v-text="$t('default.btns.menu')"
            ></v-btn>
            <v-btn
              color="landing_primary"
              class="mr-3"
              outlined
              depressed
              rounded
              :to="{ name: 'letter' }"
              v-text="$t('default.btns.carta')"
            ></v-btn>
            <v-btn
              color="landing_primary"
              class="mr-3"
              outlined
              depressed
              rounded
              :to="{ name: 'promotions-landing' }"
              v-text="$t('default.btns.promociones')"
            ></v-btn>
            <!--<v-btn
              color="landing_primary"
              class="mr-3"
              outlined
              depressed
              rounded
              :to="{ name: 'booking' }"
              v-text="$t('default.btns.reservas')"
            ></v-btn>-->
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  rounded
                  color="landing_primary"
                  v-on="on"
                  style="padding-left: 10px !important; padding-right: 10px !important"
                >
                  <div style="font-size:20px">
                    <v-icon>{{ currency }}</v-icon>
                  </div>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list v-for="(coin, indx) in coins" :key="indx">
                <v-list-item @click="changeCurrency(coin)">
                  <v-list-item-title>
                    <div style="font-size:20px">
                      <v-icon> {{ setCoinIcon(coin.cod) }} </v-icon> -
                      {{ coin.cod }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  rounded
                  color="landing_primary"
                  v-on="on"
                  style="padding-left: 10px !important; padding-right: 10px !important"
                >
                  <div style="font-size:20px">
                    <flag
                      :iso="
                        language == 'en'
                          ? 'us'
                          : language == 'zh'
                          ? 'cn'
                          : language
                      "
                    />
                  </div>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeLang('es')">
                  <v-list-item-title>
                    <div style="font-size:20px">
                      <flag iso="es" />
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLang('en')">
                  <v-list-item-title>
                    <div style="font-size:20px">
                      <flag iso="us" />
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLang('fr')">
                  <v-list-item-title>
                    <div style="font-size:20px">
                      <flag iso="fr" />
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLang('zh')">
                  <v-list-item-title>
                    <div style="font-size:20px">
                      <flag iso="cn" />
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </section>
      <section
        id="landing_menu"
        v-if="$vuetify.breakpoint.smAndDown"
        class="pa-2"
        :style="{ background: $vuetify.theme.themes.light.landing_primary }"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-toolbar
              elevation="0"
              class="d-flex justify-center bg-transparent"
              style="background: transparent"
            >
              <div>
                <v-tabs
                  class="bg-transparent"
                  slider-color="white"
                  slider-size="6"
                  height="40"
                  centered
                  hide-slider
                >
                  <v-tab
                    v-for="(menu, i) in items_menu"
                    :key="i"
                    :to="{ name: menu.to }"
                    :ripple="false"
                    active-class="active_tab_menu"
                    class="font-weight-bold px-3 bg-transparent"
                    text
                    exact
                    style="font-size: 1rem"
                  >
                    <span v-text="$t(menu.name)"></span>
                  </v-tab>
                </v-tabs>
              </div>
            </v-toolbar>
          </v-col>
        </v-row>
      </section>
      <section :class="{ 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-4">
        <v-row no-gutters>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'"
            :class="$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'px-4'"
          >
            <router-view />
          </v-col>
          <!-- SIDEBAR INFO -->
          <v-col
            cols="4"
            v-if="$vuetify.breakpoint.mdAndUp"
            class="pr-8 pl-6 pt-8 sidebar-info"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <span
                  class="font-weight-bold space-text"
                  v-text="$t('default.titulos.ubicacion')"
                ></span>
                <v-divider class="my-3"></v-divider>
              </v-col>
            </v-row>
            <show-map
              :lat="lat"
              :lng="lng"
              height="300px"
              v-if="restaurant.id > 0"
            />
            <v-row>
              <v-col cols="12" class="py-0 mt-3">
                <span
                  class="font-weight-bold space-text"
                  v-text="$t('default.titulos.detalles')"
                ></span>
                <v-divider class="my-3"></v-divider>
              </v-col>
              <v-col cols="12" class="py-0">
                <p v-text="description"></p>
                <v-list class="py-0" dense>
                  <v-list-item>
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1"
                        >mdi-map-marker</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="space-text"
                        v-text="address"
                      ></v-list-item-title>
                      <v-list-item-title
                        class="space-text"
                        v-text="city + ' ' + province"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1">mdi-phone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="space-text"
                        ><a
                          :href="'tel:' + phone"
                          target="_blank"
                          v-text="phone"
                        ></a
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="facebook !== '' && facebook !== null">
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1">mdi-facebook</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="space-text"
                        ><a :href="facebook" target="_blank"
                          >Facebook</a
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="instagram !== '' && instagram !== null">
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1"
                        >mdi-instagram</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="space-text"
                        ><a :href="instagram" target="_blank"
                          >Instagram</a
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="whatsapp !== '' && whatsapp !== null">
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1">mdi-whatsapp</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="space-text"
                        ><a
                          :href="
                            'https://api.whatsapp.com/send?phone=' +
                              whatsapp +
                              '&text=Deseo%20tener%20m%C3%A1s%20informaci%C3%B3n'
                          "
                          target="_blank"
                          v-text="whatsapp"
                        ></a
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-if="url_allergen != 'null'">
              <v-col cols="12" class="py-0 mt-8">
                <span
                  class="font-weight-bold space-text"
                  v-text="$t('default.titulos.alergenos')"
                ></span>
                <v-divider class="my-3"></v-divider>
              </v-col>
              <v-col cols="12" class="py-0 mb-12">
                <v-list class="py-0" dense>
                  <v-list-item>
                    <v-list-item-action class="mr-1">
                      <v-icon small color="grey lighten-1">mdi-web</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="space-text">
                        <a
                          href="//"
                          target="_blank"
                          @click.prevent="external"
                          v-text="url_allergen"
                        ></a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>

      <v-card-text
        style="height: 100px; position: fixed; bottom: 0px;right: 0px; "
      >
        <v-fab-transition>
          <v-btn
            @click="opencart()"
            color="landing_primary"
            dark
            absolute
            top
            right
            fab
          >
            <v-badge
              color="blue"
              :content="totalProductos"
              offset-x="20"
              offset-y="1"
              v-if="totalProductos > 0"
            >
            </v-badge>
            <v-icon v-if="cartviewer">mdi-close</v-icon>
            <v-icon v-else>mdi-cart</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-text>
    </v-content>
    <v-row justify="center">
      <v-dialog v-model="cartviewer" persistent max-width="800">
        <v-toolbar dark color="landing_primary">
          <v-toolbar-side-icon></v-toolbar-side-icon>
          <v-toolbar-title class="white--text">Carrito de compra</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="cartviewer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step color="landing_primary" :complete="e1 > 1" step="1">
              Resumen de pedido.
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              color="landing_primary"
              :complete="e1 > 2"
              step="2"
              :rules="[() => false]"
            >
              Datos de pedido
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="landing_primary"  step="3">
              Resumen final.
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="lighten-1" max-height="200px">
                <v-row
                  justify="center"
                  v-for="(producto, key) in shoppingcart.productos"
                  :key="key"
                >
                  <v-col md="3">
                    {{ producto.name }}
                  </v-col>
                  <v-col md="2">
                    {{ producto.price }}{{ producto.symbol }}
                  </v-col>
                  <v-col md="1">
                    {{ producto.cant }}
                  </v-col>
                  <v-col md="2">
                    {{ producto.type == "dishe" ? "Plato" : "Menu" }}
                  </v-col>
                  <v-col>
                    {{ producto.price * producto.cant
                    }}{{ producto.symbol }}
                  </v-col>
                  <v-col md="2">
                    <v-btn color="red" small @click="deleteProducto(producto)"
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-text class="d-block float-right center">
                  <v-chip color="green">
                    Precio total: {{ preciototal }}{{ symbol }}
                  </v-chip>
                </v-text>
              </v-card>

              <v-btn color="landing_primary" @click="datosClientes()">
                Continuar
              </v-btn>

              <v-btn text @click="cartviewer = false">
                Cancelar
              </v-btn>
            </v-stepper-content>
            <validation-observer ref="datos_contact_form" >
              <v-stepper-content step="2">
                <v-card class="mb-12" color="lighten-1" max-height="200px" style="overflow:scroll">
                  <v-row justify="center">
                    <v-col md="4">
                      <validation-provider name="nombre" vid="name" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          rounded
                          prepend-inner-icon="mdi-playlist-edit"
                          v-model="cliente.name"
                          :error-messages="errors[0]"
                          hint="Escriba su nombre"
                          persistent-hint
                        >
                        </v-text-field>
                      </validation-provider>

                    </v-col>
                    <v-col md="3">
                      <validation-provider name="apellido" vid="last_name" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          rounded
                          prepend-inner-icon="mdi-playlist-edit"
                          v-model="cliente.last_name"
                          :error-messages="errors[0]"
                          hint="Escriba su apellido"
                          persistent-hint
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col md="3">
                      <validation-provider name="telefono" vid="telephone" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        rounded
                        prepend-inner-icon="mdi-playlist-edit"
                        v-model="cliente.telephone"
                        :error-messages="errors[0]"
                        hint="Teléfono"
                        persistent-hint
                      ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col md="4">
                      <validation-provider name="correo" vid="email" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        rounded
                        prepend-inner-icon="mdi-playlist-edit"
                        v-model="cliente.email"
                        :error-messages="errors[0]"
                        hint="Correo"
                        persistent-hint
                      ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col md="12" center>
                      Hacer reserva<v-checkbox
                        v-model="revervamodal"
                      ></v-checkbox>
                    </v-col>
                    <v-col md="11" center v-if="revervamodal">
                      <calendar-reservas></calendar-reservas>
                    </v-col>
                    <v-col> </v-col>
                    <v-col md="2"> </v-col>
                  </v-row>
                </v-card>

                <v-btn color="landing_primary" @click="resumenOpen()">
                  Continuar
                </v-btn>

                <v-btn text @click="e1 = 1">
                  Atras
                </v-btn>
              </v-stepper-content>
            </validation-observer>

            <v-stepper-content step="3">
              <v-card class="mb-12" color=" lighten-1" height="200px">
                <v-row
                  justify="center"
                  v-for="(producto, key) in shoppingcart.productos"
                  :key="key"
                >
                  <v-col md="3">
                    {{ producto.name }}
                  </v-col>
                  <v-col md="2">
                    {{ producto.price }}{{ producto.symbol }}
                  </v-col>
                  <v-col md="1">
                    {{ producto.cant }}
                  </v-col>
                  <v-col md="2">
                    {{ producto.type == "dishe" ? "Plato" : "Menu" }}
                  </v-col>
                  <v-col>
                    {{ producto.price * producto.cant
                    }}{{ producto.symbol }}
                  </v-col>
                </v-row>
                <v-text class="d-block float-right center">
                  <v-chip color="green">
                    Precio total: {{ preciototal }}{{ symbol }}
                  </v-chip>
                </v-text>
                <span v-if="revervamodal"
                  >Tiene reserva, cant de comensales:
                  {{ shoppingcart.cant_comensales }}</span
                >
                <span v-else>No tiene reservas</span>
                <v-text class="d-block float-right center"> </v-text>
              </v-card>

              <v-btn color="landing_primary" @click="enviarPedido()">
                Enviar pedido
              </v-btn>

              <v-btn text @click="e1 = 2">
                Atras
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </v-row>

    <alert ref="alert"></alert>
    <v-overlay
      :value="overlay"
      :opacity="0.99"
      :z-index="10"
      color="white"
      class="text-center"
    >
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
        color="blue-grey lighten-3"
        indeterminate
        rounded
        height="4"
      ></v-progress-linear>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ShowMap from "../../components/ShowMap";
import CalendarReservas from "../../components/CalendarioReserva";
import alert from "@/components/alert.vue";
const os = require("os");
export default {
  name: "BaseView",
  components: { ShowMap, alert, CalendarReservas },
  head() {
    return {
      title: this.name_restaurant,
      link: [{ rel: "icon", type: "image/x-icon", href: this.logo }],
      meta: [
        {
          name: "description",
          content:
            "Restaurante " +
            this.name_restaurant +
            " en Todo Cartas Online: " +
            this.description
        },
        {
          property: "og:title",
          content: this.name_restaurant + " - Todo Cartas Online"
        },
        { property: "og:site_name", content: "Todo Cartas Online" },
        {
          property: "og:description",
          content:
            "Restaurante " +
            this.name_restaurant +
            " en Todo Cartas Online: " +
            this.description
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://" + os.hostname() + "/r/" + this.restaurant.url
        },
        { property: "og:image", content: this.logo }
      ]
    };
  },
  data: () => ({
    sections: [],
    items_menu: [
      {
        name: "default.btns.menu",
        to: "menus-landing"
      },
      {
        name: "default.btns.carta",
        to: "letter"
      },
      {
        name: "default.btns.promociones",
        to: "promotions-landing"
      },
      /*{
        name: "default.btns.reservas",
        to: "booking"
      },*/
      {
        name: "default.btns.info",
        to: "info-landing"
      }
    ],
    restaurant: [],
    cartviewer: false,
    clienteModal: false,
    cliente: {
      name: "",
      last_name:"",
      telephone: "",
      email: ""
    },
    revervamodal: false,
    resumenModal: false,
    e1: 1
  }),
  metaInfo() {
    return {
      title: this.name_restaurant
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$snakbar = this.$refs.alert.show;
  },
  methods: {
    changeLang(lang) {
      this.$store.commit("SET_LANGUAGE", lang);
      this.$i18n.locale = lang;
    },
    external() {
      window.location.href = "http://" + this.url_allergen;
    },
    async getData() {
      const url = this.$route.params.landing ? this.$route.params.landing : "";
      this.$store.commit("SET_OVERLAY", true);
      try {
        const { data } = await this.$api.get(`api/restaurant/${url}`);
        const color = JSON.parse(data.data.restaurant.restaurant_setup.colors);
        this.$store.commit("landing/SET_CONFIG", data.data.restaurant);
        this.$store.commit("landing/SET_COLOR", color.default);
        this.$vuetify.theme.themes.light.landing_primary = color.default;
        this.$store.commit("SET_OVERLAY", false);
        this.restaurant = data.data.restaurant;
        const coins = JSON.parse(data.data.restaurant.restaurant_setup.coins);
        //this.coins= coins;
        let coinDefault = "";

        coins.forEach(element => {
          coinDefault = element;
        });
        this.$store.commit("landing/SET_COIN", coinDefault);
        this.coin = coinDefault;
        document.styleSheets[0].addRule(
          ".active_tab_menu:after",
          "background-color:" + color.default + ";"
        );

        if (this.$route.name === "landing")
          this.$router.push({ name: "menus-landing" });
      } catch (e) {
        if (e.response.data.error_code == "400") {
          this.$store.commit("SET_OVERLAY", false);
          this.$router.push({
            name: "404",
            query: { msj: e.response.data.message }
          });
        } else {
          this.$store.commit("SET_OVERLAY", false);
          this.$router.push({ name: "404" });
        }
      }
    },
    changeCurrency(coin) {
      this.$store.commit("landing/SET_COIN", coin);
    },
    setCoinIcon(currency) {
      if (currency == "USD") {
        return "mdi-currency-usd";
      }
      if (currency == "EUR") {
        return "mdi-currency-eur";
      }
      if (currency == "COP") {
        return "mdi-currency-usd";
      }
    },
    opencart() {
      if (this.total >= 1) {
        this.cartviewer = true;
      }
    },
    deleteProducto(producto) {
      this.$store.commit("landing/DELETE_ITEM_CART", producto);
    },
    datosClientes() {

      this.e1 = 2;
    },
    resumenOpen() {
      this.$refs.datos_contact_form.validate().then(result =>{
          if(result){
            let item = {cliente : this.cliente};
            item.reservation =  this.revervamodal;
            console.log(item);
            this.$store.commit('landing/ADD_PERSON',item);
            this.e1 = 3;
          } else {
            console.log('NOOOOOOOOO');
          }
        })

      /* let item = {cliente : this.cliente};
      item.reservation =  this.revervamodal;
      console.log(item);
      this.$store.commit('landing/ADD_PERSON',item);
      this.e1 = 3; */
    },
    async enviarPedido(){
      const url = this.$route.params.landing ? this.$route.params.landing : "";
      let shoppingcart = this.shoppingCartFinal;
      await this.$api.post(`api/restaurant/${url}/order`,shoppingcart);
    }




  },
  computed: {
    ...mapGetters({
      language: "language",
      logo: "landing/logo",
      image_header: "landing/header",
      name_restaurant: "landing/name",
      description: "landing/description",
      address: "landing/address",
      city: "landing/city",
      province: "landing/province",
      phone: "landing/phone",
      email: "landing/email",
      facebook: "landing/facebook",
      instagram: "landing/instagram",
      whatsapp: "landing/whatsapp",
      lat: "landing/lat",
      lng: "landing/lng",
      url_allergen: "landing/url_allergen",
      monedas: "landing/coins",
      symbol: "landing/symbol",
      total: "landing/totalcart",
      shoppingcart: "landing/shoppingCart",
      shoppingCartFinal:"landing/shoppingCartFinal"
    }),
    ...mapState(["overlay"]),
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "40vh" : "25vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
    logoSize() {
      const size = this.$vuetify.breakpoint.mdAndUp ? "12" : "10";

      return `${size}rem`;
    },
    logoStyle() {
      return {
        top: this.$vuetify.breakpoint.mdAndUp
          ? "calc(40vh - 9rem)"
          : "calc(25vh - 7rem)"
      };
    },
    fontSize() {
      return {
        "font-size": this.$vuetify.breakpoint.mdAndUp ? "2.5rem" : "2rem"
      };
    },
    currency() {
      return this.symbol;
    },
    totalProductos() {
      return this.total;
    },
    coins() {
      return JSON.parse(this.monedas);
    },
    preciototal() {
      const productos = this.shoppingcart.productos;
      let preciototal = 0;
      productos.forEach(producto => {
        let precio = producto.price * producto.cant;
        preciototal += precio;
      });

      return preciototal;
    }
  },
  watch: {
    total: function() {
      if (this.total == 0) {
        this.cartviewer = false;
      }
    }
  }
};
</script>

<style lang="sass">
.image__logo
  z-index: 4
  position: absolute

.icon-left
  margin-left: -4px
  margin-right: 8px

.sidebar-info
  border-left: 1px solid #f5f5f5

#landing_menu
  .v-tab
    text-transform: capitalize !important
    min-width: auto !important
    &::before
      display: none

  .v-toolbar__content
    padding-left: 0px !important
    padding-right: 0px !important

  .active_tab_menu
    font-size: 1rem !important

  .active_tab_menu:after
    content: ''
    width: 10px
    height: 7px
    margin: 0 auto
    position: absolute
    bottom: 0px
    border-radius: 3px
    padding-left: 0px !important
    padding-right: 0px !important

.space-text
  letter-spacing: 1px
</style>
